/* Wrapper to constrain carousel */
.landing-carousel-container {
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
    position: relative;
}

/* Carousel Slide */
.carousel-slide {
    position: relative;
    height: 400px; /* Adjust height here */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* Carousel Image */
/* Image Styling with Filter */
.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 40%;
    filter: brightness(40%); /* Reduces brightness for dark tint */
    transition: filter 0.5s ease; /* Smooth transition for hover effect */
}

/* Optional: Brighten on Hover */
.carousel-slide:hover .carousel-image {
    filter: brightness(45%);
}


/* About Us Section - Landing  */


.slide-heading-container {
    position: absolute;
    top: 30%;
    left: 5%;
  
    color: white;
    font-size: 2rem;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
    font-weight: bold;
    text-align: left;
    opacity: 0; /* Initially hidden */
    transition: all 0.5s ease;


    display: flex;
    flex-direction: column;
    justify-content:left
}

.slider-btn-container{
    display: flex;
    justify-content: left;
    gap:10px;

    
    width: 300px;
    margin-top:20px;
}



/* Explore Services Card */

/* Carousel Container */
.carousel-container {
  position: relative;
  width: 100%; /* Takes full width */
  max-width: 1200px; /* Prevents it from being too wide */
  margin: 0 auto; /* Centers horizontally */
  overflow: hidden;
  perspective: 1500px; /* Creates depth for 3D effect */
}

/* Carousel Wrapper */
.carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 450px;
  transform-style: preserve-3d; /* Ensures 3D effect works */
  transition: transform 0.5s ease-in-out; /* Smooth transition */
}

/* Carousel Item */

.carousel-item {
  position: absolute;
  width: 350px; /* Custom width for the images */
  height: auto; /* Maintain aspect ratio */
  text-align: center;
  transition: transform 0.7s ease, opacity 0.7s ease;
  opacity: 0; /* Start hidden */
 
}

/* Carousel Item Image */
.carousel-item img {
  width: 100%; /* Match the container width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure images are cropped evenly if necessary */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better visuals */
  transition: transform 0.5s ease; /* Smooth scaling effect */
}

/* Active Item */
.carousel-item.active {
  transform: scale(1) translateX(0) rotateY(0deg);
  opacity: 1;
  z-index: 3;
}

/* Next Item */
.carousel-item.next {
  transform: scale(0.9) translateX(125%) rotateY(-35deg);
  opacity: 0.8;
  z-index: 2;
}

/* Previous Item */
.carousel-item.prev {
  transform: scale(0.9) translateX(-125%) rotateY(35deg);
  opacity: 0.8;
  z-index: 2;
}

/* Hidden Items */
.carousel-item.hidden {
  transform: scale(0.5);
  opacity: 0;
  z-index: 1;
}

/* Carousel Buttons */
.carousel-btn {
  position: absolute;
  top: 50%; /* Center vertically relative to the carousel */
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--primary-color);
  z-index: 2; /* Ensure it appears above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.carousel-btn.prev {
  left: 10px; /* Adjusted for centering */
}

.carousel-btn.next {
  right: 10px; /* Adjusted for centering */
}

/* Hover Effects */
.carousel-btn:hover {
  background: rgba(0, 0, 0, 0.9);
}

/* Responsive Design */
@media (max-width: 768px) {
  .carousel-container {
    max-width: 100%; /* Adjusts to smaller screens */
  }

  .carousel {
    height: 300px; /* Reduce height */
  }

  .carousel-item {
    transform: scale(1) translateX(0) !important;
    opacity: 1;
    z-index: 3;
    width: 90%; /* Full width for mobile screens */
  }

  .carousel-item.next,
  .carousel-item.prev,
  .carousel-item.hidden {
    display: none; /* Hide items except the active one */
  }

  .carousel-btn {
    padding: 10px;
  }
}



/* Client List Carousel Styles */


.client-carousel {
    position: relative; /* Acts as a positioning reference for buttons */
    width: 100%; /* Full width of the carousel */
  }
  
  .client-carousel-container {
    position: relative;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    width: 90%;
    margin: auto;
  }
  
  .client-carousel-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
  
  .client-carousel-item {
    display: inline-block;
    width: 30%;
    margin: 1.5% ;
    
  }

  .client-carousel-wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:10px;
  }

  .client-carousel-img{
    width: 200px;
  }

  .client-carousel-name{
    text-wrap: wrap;
    
  }
  
  .client-carousel-action {
    position: absolute;
    top: 50%; /* Center vertically relative to the carousel */
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--primary-color);
    z-index: 2; /* Ensure it appears above other elements */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    font-size: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .client-carousel-action.left-action {
    left: 10px; /* Position the left button */
  }
  
  .client-carousel-action.right-action {
    right: 10px; /* Position the right button */
  }
  
  /* .client-carousel-action.left-action:hover {
    background: rgba(0, 0, 0, 0.9);
  } */
  

  
/* Testimonial Carousel Styles */
.test-carousel {
  position: relative; /* Acts as a positioning reference for buttons */
  width: 100%; /* Full width of the carousel */
}

.test-carousel-container {
  display: flex; /* Use flex to align carousel items horizontally */
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 90%;
  margin: auto;
  scroll-behavior: smooth; /* Smooth scrolling effect */
}

.test-carousel-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.test-carousel-item {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: flex-start; /* Start content from the top */
  width: 35%;
  min-width: 320px;
  min-height: 400px;
  margin: 1.5%;
  padding: 20px; /* Add padding for better spacing */
  background: #f3f4f6; /* Light gray background */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all .3s ease;
}

.test-carousel-item:hover{

  border: 1px solid slategrey;
  box-shadow: 0 0 5px slategray;
  transform: scale(1.05);
  
}

.test-text {
  flex: 1; /* Take up the remaining space in the .test-carousel-item */
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 20px; /* Add space below text */
}

.test-carousel-action {
  position: absolute;
  top: 50%; /* Center vertically relative to the carousel */
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--primary-color);
  z-index: 2; /* Ensure it appears above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.test-carousel-action.left-action {
  left: 10px; /* Position the left button */
}

.test-carousel-action.right-action {
  right: 10px; /* Position the right button */
}




.test-client {
  display: flex;
  align-items: center; /* Align image and text vertically */
  gap: 10px; /* Spacing between image and text */
  border-top: 1px solid lightslategrey;
  padding-top: 10px; /* Padding above the client section */
  margin-top: auto; /* Pushes the .test-client section to the bottom */

}



.test-client-img {
  height: 60px;
 
 /* Make image circular */
}

.test-client-name {
  font-size: 1rem; /* Slightly larger text for better visibility */
  font-weight: bold; /* Make the name stand out */
}

.test-client-title {
  font-size: 0.9rem;
  color: gray; /* Subtle color for the title */
}

.test-text {
  text-wrap: wrap;
  text-align: justify;
  text-justify: inter-word;
}





/* Fade-in Animation */
.fade-in {
    opacity: 1;
}


@media (max-width:1200px) {
  .test-carousel-item{
    min-height: 480px;
  }
}



/* Responsive Adjustments */
@media (max-width: 768px) {
    

    .client-carousel-action, .test-carousel-action{
      top: 100%;
      transform: translateY(0);
    }

    .carousel-slide {
        height: 300px; /* Adjust for smaller screens */
    }
    .slide-heading-container {
        font-size: 1.5rem;
    }

    .client-carousel-item{
        width: 95%;
    }
}

@media (max-width: 480px) {

  .landing-carousel-container{
    height: 200px;
  }
    .carousel-slide {
        height: 200px;
    }


    .slide-heading-container {
        font-size: 1rem;
    }

    .landing-carousel-btn{
      font-size: .85rem;
    }
}

