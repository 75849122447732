:root{
    --primary-color: #5448C8;
    --secondary-color: #1a1625; 
    --primary-font: 'Poppins', sans-serif;
    --secondary-font: 'Roboto', sans-serif

}

body{
    font-family: var(--primary-font);
    overflow-x:hidden;
    color: var(--secondary-color);
}


.flex{
    gap: 10px;
}

.text-primary{
    color: var(--primary-color);
}

.text-secondary{
    color: var(--secondary-color)
}


.bg-white{
    background-color: white;
}

.bg-primary{
    background: var(--primary-color);
}


.bg-secondary{
    background: var(--secondary-color);
}



.heading-1{
    color: var(--secondary-color);
    font-size: 2rem;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
    font-weight: bold;

}

.heading-2{
    color: var(--primary-color);
    font-size: 1.2rem;

    font-weight: 700;
    text-transform: uppercase;


}

.para-text{
    color: var(--secondary-color);
    font-size: .9rem;
    font-weight: normal;
}



.btn{
    font-family: var(--secondary-font);
    padding: 12px 16px;
    color: white;
    border-radius: 9px;
    transition: 0.3s ease-in-out;
    font-size:0.9rem;
    font-weight: normal

}


.btn:hover{
    transform: translateY(-3px)

}

.primary-btn{
    font-family: 'Poppins', sans-serif;
    background-color: var(--primary-color);

}

.secondary-btn{
    font-family: 'Poppins', sans-serif;
    background-color: var(--secondary-color);
}

.text-with-icon{
    display: inline-flex;
    vertical-align: middle;
    gap: 5px;
}