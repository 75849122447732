.page-banner{
    height: 250px;
    width: 100%;
    background-image: url(/src/images/Banner-bg.webp);
    background-size: cover;
    background-position:center ;

    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-heading{
    font-size: 2rem;
    font-weight: bolder;
    color: whitesmoke;
    text-align: center;
}