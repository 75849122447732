.logo-banner-wrapper{
    width: 100%;
    max-width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
   
    color: black;
}

.logo-banner-phone{
    font-size: 12px;

}


   

.custom-navbar{
    background-color: var(--primary-color);
}

.dropdown-menu{
    position: absolute;
    top:80px;
    left:0;
    background-color: red;
    width: 80vw;
    z-index:1;
}



.footer-container{
    position: relative;
    padding-top:220px



}

.footer-contact-form-container{
    
    width: 90%;
    height: 320px;
   
    position: absolute;
    top:0;
    right:5%;
    padding: 15px;
    
}

.footer-contact-form-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
}

.contact-img-container{
    flex: 1;
    display: flex;
    align-items: center;
 
}

.contact-form-container{
    flex:6;

    
}

.footer-contact-form{
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
}


.input-field-container{
    display: flex;
    font-family: var(--secondary-font);
    gap:3.33%;
    flex-wrap: wrap;
    
}

.input-field{
    width: 31%;
    padding: 10px 12px;
    border-radius:3px;
    background-color: #dddd;
}


.form-msg{
    font-family: var(--secondary-font);
    resize: none;
    width: 100%;
    background-color: #dddd;
    border-radius: 3px;
    padding: 10px 12px;
    
}

.submit-btn{
    padding: 10px 12px;
    width: 100%;
    background-color: var(--secondary-color);
    color: white;
    border-radius: 3px;
    cursor: pointer;
}


@media (max-width: 768px){


    .footer-container{
        padding-top: 750px;
    }

    .footer-contact-form-wrapper{
        flex-direction: column;
        height: 800px;
     
    }

    .input-field-container{
        flex-direction: column;
        gap: 10px;
    }

    .input-field{
        width: 100%;
    }

    .contact-img-container{
        flex: 1
    }

    .contact-form-container{
        flex: 1;
    } 
}






