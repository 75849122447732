.career-card-container{
    width: 90%;
    margin:auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.career-card{
    width:40%;
    min-width: 300px;
    height: auto;
    min-height: 500px;
   
    
    display: flex;
    flex-direction: column;
    
    gap: 15px;
    padding: 20px;

}

.career-heading-container{
    display: flex;
    flex-direction: column;
    gap: 10px;

}


.career-card-heading{
    font-size: 1.4rem;
    font-weight: bold;
}

.career-location-timing{
    display: flex;
    gap: 10px;
}


.career-heading{
    font-size: 1rem;
    font-weight: bold;
}

.career-qualification-list{
    list-style: disc;
    padding-left: 20px;
    font-size: .9rem;
}

.career-skills-description{
    font-size: .95rem;
    padding-left: 10px
}

.career-btn-container{
    margin-top: 10%
}

