.heading-container{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
}

.main-heading,.sub-heading{
    text-align: center;
}

.heading-underline{
    position: relative;
    width: 80px;
    height: 4px;
    background-color: var(--primary-color);
    border-radius: 5px;
    transform: skew(20deg);
}

.heading-underline::after{
    position:absolute;
    content: '';
    top: 0;
    left:60%;
    width: 15px ;
    height: 100%;
    border-radius: 5px;
    background-color: var(--secondary-color);

}